import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { CoreModule } from '../core/core.module';
import { GestioneTipiPrestazioneComponent } from './tipi-prestazione/gestione-tipi-prestazione/gestione-tipi-prestazione.component';
import { RicercaTipiPrestazioneComponent } from './tipi-prestazione/ricerca-tipi-prestazione/ricerca-tipi-prestazione.component';
import { DettaglioTipiPrestazioneComponent } from './tipi-prestazione/dettaglio-tipi-prestazione/dettaglio-tipi-prestazione.component';
import { TipiPrestazioneService } from '../services/amministrazione/tipi-prestazione.service';
import { ClassiEconomicitaService } from '../services/strutture/classi-economicita.service';
import { TipoPrestazioneSelectComponent } from './tipo-prestazione-select/tipo-prestazione-select.component';
import { GestioneTipiContattoStrutturaComponent } from './tipi-contatto-struttura/gestione-tipi-contatto-struttura/gestione-tipi-contatto-struttura.component';
import { DettaglioTipiContattoStrutturaComponent } from './tipi-contatto-struttura/dettaglio-tipi-contatto-struttura/dettaglio-tipi-contatto-struttura.component';
import { RicercaTipiContattoStrutturaComponent } from './tipi-contatto-struttura/ricerca-tipi-contatto-struttura/ricerca-tipi-contatto-struttura.component';
import { TipiContattoStrutturaService } from '../services/amministrazione/tipi-contatto-struttura.service';
import { GestioneCaricamentoMediciComponent } from './caricamento-medici/gestione-caricamento-medici/gestione-caricamento-medici.component';
import { CaricamentoMediciService } from '../services/amministrazione/caricamento-medici.service';

@NgModule({
    declarations: [
        GestioneTipiPrestazioneComponent,
        RicercaTipiPrestazioneComponent,
        DettaglioTipiPrestazioneComponent,
        TipoPrestazioneSelectComponent,
        GestioneTipiContattoStrutturaComponent,
        DettaglioTipiContattoStrutturaComponent,
        RicercaTipiContattoStrutturaComponent,
        GestioneCaricamentoMediciComponent
    ],
    imports: [
        CommonModule,
        CoreModule
    ],
    providers: [
        TipiPrestazioneService,
        ClassiEconomicitaService,
        TipiContattoStrutturaService,
        CaricamentoMediciService
    ],
    entryComponents: [
        GestioneTipiPrestazioneComponent,
        GestioneTipiContattoStrutturaComponent,
        GestioneCaricamentoMediciComponent
    ]
})

export class AmministrazioneModule {
    constructor(private injector: Injector) {
        const elements: any[] = [
            [GestioneTipiPrestazioneComponent, 'app-gestione-tipi-prestazione'],
            [GestioneTipiContattoStrutturaComponent, 'app-gestione-tipi-contatto-struttura'],
            [GestioneCaricamentoMediciComponent, 'app-gestione-caricamento-medici']
        ];

        for (const [component, name] of elements) {
            const el = createCustomElement(component, { injector: this.injector });
            customElements.define(name, el);
        }
    }
}
