import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { CoreModule } from '../core/core.module';
import { TipiPrestazioniComponent } from './tipi-prestazioni/tipi-prestazioni.component';
import { StatisticheService } from '../services/statistiche/statistiche.service';

@NgModule({
    declarations: [
        TipiPrestazioniComponent,
    ],
    imports: [
        CommonModule,
        CoreModule
    ],
    exports: [
        TipiPrestazioniComponent
    ],
    providers: [
        StatisticheService
    ],
    entryComponents: [
        TipiPrestazioniComponent,
    ]
})

export class StatisticheModule {
    constructor(private injector: Injector) {
        const elements: any[] = [
            [TipiPrestazioniComponent, 'app-tipi-prestazioni-statistiche'],
        ];

        for (const [component, name] of elements) {
            const el = createCustomElement(component, { injector: this.injector });
            customElements.define(name, el);
        }
    }
}
