import { AuthenticationService } from './authentication.service';
import { AlertService } from '../../services/core/alert.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(protected http: HttpClient, protected authService: AuthenticationService, protected alertService: AlertService) { }

  protected jwt() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getUserToken(),
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache'
    });

    return {
        headers: headers,
    };
  }

  protected jwtFiles() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getUserToken(),
      'reportProgress': 'true'
      }
    );

    return {
        headers: headers,
    };
  }

  protected jwtFilesExcel() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getUserToken(),
      'reportProgress': 'true'
    });

    return {
      headers: headers,
      responseType: 'blob' as 'json'
    };
  }

  protected handleError(error: Response | any) {
    console.log('ERRORE');
    console.log(error);

    if (error.status === 401) {
      this.authService.logout();
      return Observable.throw('Sessione scaduta. Eseguire nuovamente il login.');
    }

    let errMsg: string;
    if (error instanceof Response) {
      const err = JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.alertService.error(errMsg);
    return Observable.throw(errMsg);
  }
}
