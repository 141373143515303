export const environment = {
  production: true,
  titoloApplicazione : 'Strutture TEST',
  // apiEndpoint: 'https://localhost:5000',
  // urlStrutture: 'http://localhost:3434/'

  //apiEndpoint: 'http://networkwebapitest.coopsalute.com',
  //urlStrutture: 'http://192.168.100.2:3431/'

  apiEndpoint: 'https://networkwebapi.healthassistance.it',
  urlStrutture: 'https://network.healthassistance.it',
};
