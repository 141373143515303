import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { AuthenticationService } from './../../services/core/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  isOpen: boolean = false;

  currentUser = {
    photoURL: null,
    currentUserName: 'Test Prova'
  };

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
      return;
  }

  const clickedInside = this.elementRef.nativeElement.contains(targetElement);
  if (!clickedInside) {
    this.isOpen = false;
    }
  }

  constructor(private elementRef: ElementRef, private authService: AuthenticationService) { }

  ngOnInit() {
  }

  logout() {
    this.authService.logout();
  }
}
