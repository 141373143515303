import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TipoPrestazioneStruttura } from '../../model/strutture/tipo-prestazione-struttura';
import { environment } from '../../../environments/environment';
import { ReturnValue } from '../../model/common/return-value';

@Injectable({
  providedIn: 'root'
})
export class TipiPrestazioneStrutturaService extends BaseService {

  getTipiPrestazioneByCodStruttura(codStruttura: number): Observable<TipoPrestazioneStruttura[]> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazioneStruttura/GetTipoPrestazioneStrutture?idStruttura=${codStruttura}`;
    return this.http.get<TipoPrestazioneStruttura[]>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  salvaTipiPrestazioneStruttura(tipiToSave: TipoPrestazioneStruttura[]): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazioneStruttura`;
    const objToSend = JSON.stringify(tipiToSave);
    return this.http.put<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
}
