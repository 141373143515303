import { Component } from '@angular/core';

@Component({
  selector: 'app-gestione-tipi-contatto-struttura',
  templateUrl: './gestione-tipi-contatto-struttura.component.html'
})
export class GestioneTipiContattoStrutturaComponent {
  public _idDettaglio = 0;

  dettaglioSelezionato(idDettaglio: number) {
    this._idDettaglio = idDettaglio;
  }

  chiudiDettaglio() {
    this._idDettaglio = 0;
  }
}
