import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Nazione } from 'src/app/model/common/nazione';
import { Localizzazione } from 'src/app/model/common/localizzazione';

@Component({
  selector: 'app-localizzazione-dialog',
  templateUrl: './localizzazione-dialog.component.html',
  styleUrls: ['./localizzazione-dialog.component.scss']
})

export class LocalizzazioneDialogComponent implements OnInit {
  public _nazioni: Nazione[] = new Array<Nazione>();
  public _localizzazioni: Localizzazione[] = new Array<Localizzazione>();

  constructor(@Inject(MAT_DIALOG_DATA) public _data: any = null,
        public dialogRef: MatDialogRef<LocalizzazioneDialogComponent> = null,
  ) {
    if (_data) {
      this._nazioni = _data.nazioni;
      this._localizzazioni = _data.localizzazione;
    }
  }

  ngOnInit() {
  }

  chiudi() {
    this._localizzazioni = null;
    this._nazioni = null;
    if (this.dialogRef !== undefined && this.dialogRef !== null ) {
      this.dialogRef.close();
    }
  }

}
