import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClasseEconomicita } from '../../model/strutture/classe-economicita';

@Injectable({
  providedIn: 'root'
})
export class ClassiEconomicitaService extends BaseService {

  getClassiEconomicita(): Observable<ClasseEconomicita[]> {
    const urlParams = `${environment.apiEndpoint}/ClasseEconomicita/GetAll`;
    return this.http.get<ClasseEconomicita[]>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
}
