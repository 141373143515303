import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { LoaderService } from '../../services/core/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;

  @Output()
  onLoadingEnd: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private loaderService: LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      //console.log(v);
       this.loading = v;
      //this.loading = true;
      this.onLoadingEnd.emit(v);
    });
  }
  ngOnInit() {
  }

}
