import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TipoContattoStruttura } from '../../model/strutture/tipo-contatto-struttura';
import { ListaBase } from '../../model/core/lista-base';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ReturnValue } from '../../model/common/return-value';

@Injectable({
  providedIn: 'root'
})
export class TipiContattoStrutturaService extends BaseService {

  get(id: number): Observable<TipoContattoStruttura> {
    const urlParams = `${environment.apiEndpoint}/TipiContattoStruttura/${id}`;
    return this.http.get<TipoContattoStruttura>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getAll(): Observable<Array<TipoContattoStruttura>> {
    const urlParams = `${environment.apiEndpoint}/TipiContattoStruttura/GetAll`;
    return this.http.get<Array<TipoContattoStruttura>>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getRicerca(descrizione: string, start: number, length: number): Observable<ListaBase<TipoContattoStruttura>> {
    if (!descrizione) {
      descrizione = '';
    }

    let urlParams = `${environment.apiEndpoint}/TipiContattoStruttura/GetRicerca`;
    urlParams += `?descrizione=${encodeURIComponent(descrizione)}&start=${start}&length=${length}`;

    return this.http.get<ListaBase<TipoContattoStruttura>>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  salva(objToSave: TipoContattoStruttura): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/TipiContattoStruttura/`;
    const objToSend = JSON.stringify(objToSave);
    if (objToSave.id === undefined || objToSave.id === 0) {
        return this.http.post<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
    }
    return this.http.put<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  elimina(id: number): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazione/${id}`;
    return this.http.delete<ReturnValue>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
}
