import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TipoPrestazione } from '../../model/strutture/tipo-prestazione';
import { TipiPrestazioneService } from '../../services/amministrazione/tipi-prestazione.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'app-tipo-prestazione-select',
  templateUrl: './tipo-prestazione-select.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class TipoPrestazioneSelectComponent {
  public _titolo: string;
  public _tipiPrestazione: Array<TipoPrestazione>;
  public _tipiPrestazioneFiltered: Array<TipoPrestazione>;
  public _tipoPrestazione: TipoPrestazione = new TipoPrestazione();

  @Input()
  set titolo (value: string) {
    this._titolo = value;
  }

  @Input()
  set tipo(value: TipoPrestazione) {
    this._tipoPrestazione = value;
  }

  @Output() tipoChange = new EventEmitter<TipoPrestazione>();

  constructor(protected tipiSvc: TipiPrestazioneService) {
    this.tipiSvc.getAll().subscribe(
      dataStat => {
        this._tipiPrestazione = dataStat;
        this._tipiPrestazioneFiltered = dataStat;
      });
  }

  send(val) {
    this._tipoPrestazione = val;
    this.tipoChange.emit(val);
  }

  searchTipo(val: string) {
    if (val && val.toLowerCase) {
      this._tipiPrestazioneFiltered = this._tipiPrestazione.filter(tp => tp.descrizione.toLowerCase().startsWith(val.toLowerCase()));
    }
  }

  displayTipo(tipo: TipoPrestazione): string {
    if (tipo === undefined) {
      return;
    }
    return tipo ? tipo.descrizione : null;
  }
}
