import { Component, OnInit } from '@angular/core';
import { User } from '../../../model/core/user';
import { AlertService } from '../../../services/core/alert.service';
import { AuthenticationService } from '../../../services/core/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})

export class LoginComponent implements OnInit {
  public model: User = new User();
  public loading = false;
  public returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    protected alertService: AlertService
  ) { }

  ngOnInit() {
    // this.authenticationService.logout();
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.authenticationService
      .login(this.model.userName, this.model.password)
      .subscribe(
      data => {
        const utente: User = this.authenticationService.getCurrentUser();
        if (utente.error && utente.error !== '') {
            this.alertService.error(utente.error);
        } else {
          // this.router.navigate([utente.redirectUrl]);
          console.log('reidrect:' + utente.redirectUrl);
          window.location.href = `${utente.redirectUrl}`;
        }

        // if (utente.cambioPasswordObbligatorio === true)
        // {
        //   this.router.navigate(['changepassword']);
        // } else {
        //   this.router.navigate(['home']);
        // }
        }
      );
  }

}
