import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TipoPrestazione } from '../../../model/strutture/tipo-prestazione';
import { AlertService } from '../../../services/core/alert.service';
import { TipiPrestazioneService } from '../../../services/amministrazione/tipi-prestazione.service';

@Component({
  selector: 'app-dettaglio-tipi-prestazione',
  templateUrl: './dettaglio-tipi-prestazione.component.html'
})

export class DettaglioTipiPrestazioneComponent {
  public _tipoSelezionato: TipoPrestazione;

  @Input()
  set idDettaglioSelezionato(value: number) {
    if (value > 0) {
      this.tipiSvc.get(value).subscribe(
        data => {
          this._tipoSelezionato = data;
        },
        error => {
          this.alertService.error(error);
        });
    } else {
      this._tipoSelezionato = new TipoPrestazione();
    }
  }

  @Output()
  OnDettaglioClosed = new EventEmitter();

  constructor(protected tipiSvc: TipiPrestazioneService,
    protected alertService: AlertService
  ) { }

  salva(isValid: boolean) {
    if (!isValid) {
      this.alertService.error(
        'Attenzione, alcuni campi obbligatori non sono stati compilati correttamente, ricontrollare eventuali errori.');
      return;
    }
    this.alertService.reset();

    if (this._tipoSelezionato.padre && this._tipoSelezionato.padre.id) {
      this._tipoSelezionato.idPadre = this._tipoSelezionato.padre.id;
    }

    this.tipiSvc.salva(this._tipoSelezionato).subscribe(
      data => {
        if (data.result > 0) {
          this.alertService.success('Salvataggio completato con successo!');
          this.OnDettaglioClosed.emit();
        } else {
          this.alertService.error(data.error);
        }
      },
      error => {
        this.alertService.error(error);
      });
  }

  chiudi() {
    this._tipoSelezionato = null;
    this.OnDettaglioClosed.emit();
  }
}
