import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { StruttureModule } from './strutture/strutture.module';
import { AmministrazioneModule } from './amministrazione/amministrazione.module';
import { StatisticheModule } from './statistiche/statistiche.module';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CoreModule,
        StruttureModule,
        StatisticheModule,
        AmministrazioneModule
    ],
})

export class AppModule {
    ngDoBootstrap() { }
}
