import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../../model/core/user';
import { UserChangePassword } from '../../model/core/user-change-password';
import { ReturnValue } from '../../model/common/return-value';
import { UserResetPassword } from '../../model/core/user-reset-password';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _isUserLogged = new BehaviorSubject<boolean>(this.isUserLogged());

  isUserLogged$ = this._isUserLogged.asObservable();

  constructor(
    protected http: HttpClient,
    protected alertService: AlertService
  ) {
  }

  changeNav(newValue) {
    this._isUserLogged.next(newValue);
  }

  login(username: string, password: string): Observable<void> {
    return this.http.post<User>(`${environment.urlStrutture}/Account/LogOn`, { userName: username, password: password }).pipe(
      map((data) => { 
        if (data) {
            localStorage.setItem('currentUserNetwork', JSON.stringify(data));
            if (!data.error) {
              this.changeNav(true);
            }
        }
      }),
      catchError(error => this.handleError(error)), );
  }

  resetNeedChangePassword() {
    const localUser: string = localStorage.getItem('currentUserNetwork');
    if (localUser) {
      const currentUserNetwork: User = JSON.parse(localUser) as User;
      currentUserNetwork.cambioPasswordObbligatorio = false;
      localStorage.setItem('currentUserNetwork', JSON.stringify(currentUserNetwork));
    }
    this.changeNav(true);
    //this.router.navigate(['/Home']);
  }

  changePassword(userforchangepassword: UserChangePassword): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/account/ChangePassword`;
    const objToSend = JSON.stringify(userforchangepassword);
    return this.http.post<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getCurrentUser(): User {
    const localUser: string = localStorage.getItem('currentUserNetwork');
    if (localUser) {
      return JSON.parse(localUser) as User;
    }
    this.changeNav(false);
    // this.router.navigate(['/login']);
    return undefined;
  }

  getUserToken() {
    const localUser: string = localStorage.getItem('currentUserNetwork');
    if (localUser) {
      const currentUser: User = JSON.parse(localUser) as User;
      return currentUser.token;
    }
    this.changeNav(false);
    // this.router.navigate(['/login']);
    return undefined;
  }

  isUserLogged() {
    const localUser: string = localStorage.getItem('currentUserNetwork');
    return localUser !== null && localUser !== undefined;
  }

  logout() {
    localStorage.removeItem('currentUserNetwork');
    this.changeNav(false);
    // this.router.navigate(['/login']);
  }

  save(utente: User) {
    const urlParams = `${environment.apiEndpoint}/account`;
    const objToSend = JSON.stringify(utente);
    localStorage.setItem('currentUserNetwork', objToSend);
    return this.http.put<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  checkAbilitazioneUtente(url: string): Observable<boolean> {
    const urlParams = `${environment.apiEndpoint}/account/getAccessoUtente?root=${url}`;
    return this.http.get<boolean>(urlParams, this.jwt()).pipe(
      catchError(error => this.handleError(error)
      ));
  }

  private jwt() {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getUserToken(),
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json'
    });

    return {
      headers: headers,
    };
  }

  private handleError(error: Response | any) {
    console.log('ERRORE');
    console.log(error);

    if (error.status === 401) {
      this.logout();
      this.alertService.error('Login fallito, ricontrollare username e Password');
      return Observable.throw('Login fallito, ricontrollare username e Password.');
    }

    let errMsg: string;
    if (error instanceof Response) {
      const err = JSON.stringify(error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    this.alertService.error(errMsg);
    return Observable.throw(errMsg);
  }

  resetPassword(userResetPassword: UserResetPassword): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/account/RichiestaResetPassword`;
    const objToSend = JSON.stringify(userResetPassword);
    return this.http.post<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  resetPasswordAdmin(userResetPassword: UserResetPassword): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/account/RichiestaResetPasswordAdmin`;
    const objToSend = JSON.stringify(userResetPassword);
    return this.http.post<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
}
