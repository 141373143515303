
import { Localizzazione } from '../common/localizzazione';
import { TipoPrestazione } from './tipo-prestazione';

export class TipoPrestazioneStruttura {
    id: number;
    tipoPrestazione: TipoPrestazione;
    codStruttura: number;
    isIndiretta: boolean;
    isDiretta: boolean;
    isSsn: boolean;
    isDomiciliare: boolean;
    codClasseEconomicita: number;
    children: TipoPrestazioneStruttura[];
}
