import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../../core/confirmation-dialog/confirmation-dialog.component';
import { TipiContattoStrutturaService } from '../../../services/amministrazione/tipi-contatto-struttura.service';
import { AlertService } from '../../../services/core/alert.service';

@Component({
  selector: 'app-ricerca-tipi-contatto-struttura',
  templateUrl: './ricerca-tipi-contatto-struttura.component.html'
})
export class RicercaTipiContattoStrutturaComponent {
  public descrizione: string;
  public data = new MatTableDataSource();

  displayedColumns = ['descrizione', 'buttons'];

  @Output()
  OnDettaglioSelected: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(protected tipiSvc: TipiContattoStrutturaService,
    protected alertService: AlertService,
    public dialog: MatDialog) {
    }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.loadData();
  }

  loadData() {
    this.alertService.reset();

    this.tipiSvc.getRicerca(this.descrizione, this.paginator.pageIndex, this.paginator.pageSize).subscribe(
      dataStat => {
          if (dataStat && dataStat.results && dataStat.results.length > 0) {
            this.data.data = dataStat.results;
            this.paginator.length = dataStat.totalCount;
          } else {
            this.data = new MatTableDataSource();
            this.paginator.length = 0;
          }
      },
      error => {
        this.alertService.error(error);
      });
  }

  selezionaDettaglio(idSelezionato: number) {
    this.alertService.reset();
    this.OnDettaglioSelected.emit(idSelezionato);
  }

  elimina(id: number) {
    this.alertService.reset();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicuro di voler eliminare il tipo contatto selezionato?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tipiSvc.elimina(id).subscribe(
        retVal => {
            if (retVal.result > 0) {
              this.loadData();
              this.alertService.success('Tipo Contatto eliminato con successo.');
            } else {
              this.alertService.error(retVal.error);
            }
        },
        error => {
          this.alertService.error(error);
        });
      }
    });
  }

  inserisci() {
    this.alertService.reset();
    this.OnDettaglioSelected.emit(-1);
  }

  resetRicerca() {
    this.alertService.reset();
    this.descrizione = null;
    this.data = new MatTableDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.length = 0;
  }
}
