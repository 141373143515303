import { VociMenu } from './voci-menu';

export class User {
    idUtente: number;
    codTipoOperatore: number;
    codEsternoOperatore: number;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    token: string;
    cambioPasswordObbligatorio: boolean;
    menu: VociMenu[];
    ruoli: number[];

    error: string;
    redirectUrl: string;
}
