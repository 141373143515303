import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../../core/confirmation-dialog/confirmation-dialog.component';
import { TipoPrestazione } from '../../../model/strutture/tipo-prestazione';
import { AlertService } from '../../../services/core/alert.service';
import { TipiPrestazioneService } from '../../../services/amministrazione/tipi-prestazione.service';

@Component({
  selector: 'app-ricerca-tipi-prestazione',
  templateUrl: './ricerca-tipi-prestazione.component.html'
})

export class RicercaTipiPrestazioneComponent {
  public descrizione: string;
  public tipoPrestazione: TipoPrestazione = new TipoPrestazione();;
  public data = new MatTableDataSource();

  displayedColumns = ['padre', 'descrizione', 'buttons'];

  @Output()
  OnDettaglioSelected: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(protected tipiSvc: TipiPrestazioneService,
    protected alertService: AlertService,
    public dialog: MatDialog) {
    }

  onSubmit() {
    this.paginator.pageIndex = 0;
    this.loadData();
  }

  loadData() {
    this.alertService.reset();

    this.tipiSvc.getRicerca(this.tipoPrestazione, this.descrizione, this.paginator.pageIndex, this.paginator.pageSize).subscribe(
      dataStat => {
          if (dataStat && dataStat.results && dataStat.results.length > 0) {
            this.data.data = dataStat.results;
            this.paginator.length = dataStat.totalCount;
          } else {
            this.data = new MatTableDataSource();
            this.paginator.length = 0;
          }
      },
      error => {
        this.alertService.error(error);
      });
  }

  selezionaDettaglio(idSelezionato: number) {
    this.alertService.reset();
    this.OnDettaglioSelected.emit(idSelezionato);
  }

  elimina(id: number) {
    this.alertService.reset();

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Sei sicuro di voler eliminare il tipo prestazione selezionato?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tipiSvc.elimina(id).subscribe(
        retVal => {
            if (retVal.result > 0) {
              this.loadData();
              this.alertService.success('Tipo Prestazione eliminato con successo.');
            } else {
              this.alertService.error(retVal.error);
            }
        },
        error => {
          this.alertService.error(error);
        });
      }
    });
  }

  inserisci() {
    this.alertService.reset();
    this.OnDettaglioSelected.emit(-1);
  }

  resetRicerca() {
    this.alertService.reset();
    this.descrizione = null;
    this.tipoPrestazione = null;
    this.data = new MatTableDataSource();
    this.paginator.pageIndex = 0;
    this.paginator.length = 0;
  }

}
