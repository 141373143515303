import { Component, OnInit, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material';
import { Observable, of as observableOf} from 'rxjs';
import { ClasseEconomicita } from '../../model/strutture/classe-economicita';
import { TipoPrestazione } from '../../model/strutture/tipo-prestazione';
import { AlertService } from '../../services/core/alert.service';
import { ClassiEconomicitaService } from '../../services/strutture/classi-economicita.service';
import { TipoPrestazioneStruttura } from '../../model/strutture/tipo-prestazione-struttura';
import { TipiPrestazioneStrutturaService } from '../../services/strutture/tipi-prestazione-struttura.service';


export class FileFlatNode {
  constructor(
    public expandable: boolean,
    public descrizione: string,
    public level: number,
    public idTipoPrestazione: number,
    public idTipoStruttura: number,
    public idPadre: number,
    public isIndiretta: boolean,
    public isDiretta: boolean,
    public isSsn: boolean,
    public isDomiciliare: boolean,
    public richiediClasseEconomicita: boolean,
    public codClasseEconomicita: number
  ) { }
}

@Component({
  selector: 'app-tipi-prestazioni-struttura',
  templateUrl: './tipi-prestazioni-struttura.component.html',
  styleUrls: ['./tipi-prestazioni-struttura.component.scss']
})
export class TipiPrestazioniStrutturaComponent implements OnInit {
  public _idStrutturaSelezionata: number;
  public _classiEconomicita: ClasseEconomicita[];

  public treeControl: FlatTreeControl<FileFlatNode>;
  public treeFlattener: MatTreeFlattener<TipoPrestazioneStruttura, FileFlatNode>;
  public dataSource: MatTreeFlatDataSource<TipoPrestazioneStruttura, FileFlatNode>;

  @Input()
  set codStruttura (value: number) {
    if (!value || isNaN(Number(value))) {
      return;
    }
    this._idStrutturaSelezionata = value;
    this.getTipiPrestazioneStruttura(value);
  }

  constructor(
    protected _tipiSvc: TipiPrestazioneStrutturaService,
    protected _classiSvc: ClassiEconomicitaService,
    protected _alertSvc: AlertService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel, this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
   }

   transformer = (node: TipoPrestazioneStruttura, level: number) => {
    return new FileFlatNode(node.children.length > 0,
                            node.tipoPrestazione.descrizione,
                            level,
                            node.tipoPrestazione.id,
                            node.id,
                            node.tipoPrestazione.idPadre,
                            node.isIndiretta,
                            node.isDiretta,
                            node.isSsn,
                            node.isDomiciliare,
                            node.tipoPrestazione.richiediClasseEconomicita,
                            node.codClasseEconomicita
                    );
  }

  _getChildren (node: TipoPrestazioneStruttura): Observable<TipoPrestazioneStruttura[]> {
   return observableOf(node.children);
  }

  hasChild (_num: number, _nodeData: FileFlatNode) {
    return _nodeData.expandable;
  }

  private _getLevel = (node: FileFlatNode) => node.level;
  private _isExpandable = (node: FileFlatNode) => node.expandable;

  ngOnInit() {
    this.getClassiEconomicita();
  }

  getTipiPrestazioneStruttura(codStruttura: number) {
    this._tipiSvc.getTipiPrestazioneByCodStruttura(codStruttura).subscribe(
      data => {
         this.dataSource.data = data;
      },
      error => {
        this._alertSvc.error(error);
      });
  }

  getClassiEconomicita() {
    this._classiSvc.getClassiEconomicita().subscribe(
      data => {
         this._classiEconomicita = data;
      },
      error => {
        this._alertSvc.error(error);
      });
  }

  checkChanged(proprieta: string, node: FileFlatNode, checkPadre: boolean) {
    const changed = new Array<FileFlatNode>();
    changed.push(node);

    if (node.expandable) {
      const figli: FileFlatNode[] = this.treeControl.getDescendants(node).filter(ts => ts[proprieta] !==  node[proprieta]);
      figli.forEach(element => {
        element[proprieta] = node[proprieta];
        changed.push(element);
      });
    }

    if (checkPadre) {
      let padre: FileFlatNode = this.getParent(node);
      while (padre) {
        if (node[proprieta]) {
          if (padre[proprieta] !== node[proprieta]) {
            padre[proprieta] = node[proprieta];
            changed.push(padre);
          }
        } else {
          const figliPadre: FileFlatNode[] = this.treeControl.getDescendants(padre);
          const figlioSel = figliPadre.find(fp => fp[proprieta]);

          if (!figlioSel && padre[proprieta] === true) {
            padre[proprieta] = false;
            changed.push(padre);
          }
        }
        padre = this.getParent(padre);
      }
    }
    this.salvaTipi(changed);
  }

  getParent(node: FileFlatNode): FileFlatNode {
    const { treeControl } = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  salvaTipi(nodes: FileFlatNode[]) {
    if (!nodes || nodes.length === 0) {
      return;
    }

    const tipiToSave = new Array<TipoPrestazioneStruttura>();
    nodes.forEach(element => {
      const tipoToSave: TipoPrestazioneStruttura = new TipoPrestazioneStruttura();
      tipoToSave.codClasseEconomicita = element.codClasseEconomicita;
      tipoToSave.codStruttura = this._idStrutturaSelezionata;
      tipoToSave.id = element.idTipoStruttura;
      tipoToSave.isDiretta = element.isDiretta;
      tipoToSave.isIndiretta = element.isIndiretta;
      tipoToSave.isSsn = element.isSsn;
      tipoToSave.isDomiciliare = element.isDomiciliare;
      tipoToSave.tipoPrestazione = new TipoPrestazione();
      tipoToSave.tipoPrestazione.id = element.idTipoPrestazione;
      tipiToSave.push(tipoToSave);
    });

    this._tipiSvc.salvaTipiPrestazioneStruttura(tipiToSave).subscribe(
      data => {
      },
      error => {
        this._alertSvc.error(error);
      });
  }

}
