import { Component } from '@angular/core';
import { CaricamentoMediciService } from 'src/app/services/amministrazione/caricamento-medici.service';

@Component({
  selector: 'app-gestione-caricamento-medici',
  templateUrl: './gestione-caricamento-medici.component.html'
})
export class GestioneCaricamentoMediciComponent {
  private fileToUpload: File | null = null;
  public fileName: string = '';
  public errorText: string = '';

  constructor(protected caricamentoSvc: CaricamentoMediciService) { }


  onClick() {
    const mediciUpload = document.getElementById('mediciUpload') as HTMLInputElement;
    
    mediciUpload.onchange = () => {
        if (mediciUpload != null && mediciUpload.files.length > 0) {
          this.fileToUpload = mediciUpload.files[0];
          this.fileName = mediciUpload.files[0].name;
        } else {
          this.fileToUpload = null; 
          this.fileName = '';
        }
        this.errorText = '';
    };
    mediciUpload.click();
}

  uploadFileToActivity() {
    if (this.fileToUpload == null) {
      this.errorText = 'Selezionare il file da importare';
      return;
    }

    this.caricamentoSvc.postFile(this.fileToUpload).subscribe(data => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "resultsMedici.xlsx";
      link.click();
      
      }, error => {
        console.log(error);
        this.errorText = error;
      });
  }
}
