import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/core/alert.service';
import { StatisticheService } from '../../services/statistiche/statistiche.service';

@Component({
  selector: 'app-tipi-prestazioni',
  templateUrl: './tipi-prestazioni.component.html'
})
export class TipiPrestazioniComponent {

  constructor(protected statSvc: StatisticheService,
    protected alertService: AlertService) {
  }

  strutturePerTipoPrestazione() {
    this.statSvc.strutturePerTipoPrestazione();
  }

  struttureSenzaTipoPrestazione() {
    this.statSvc.struttureSenzaTipoPrestazione();
  }
}
