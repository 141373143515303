import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { CoreModule } from '../core/core.module';
import { TipiPrestazioniStrutturaComponent } from './tipi-prestazioni-struttura/tipi-prestazioni-struttura.component';
import { TipiPrestazioneStrutturaService } from './../services/strutture/tipi-prestazione-struttura.service';
import { ClassiEconomicitaService } from './../services/strutture/classi-economicita.service';

@NgModule({
    declarations: [
        TipiPrestazioniStrutturaComponent,
    ],
    imports: [
        CommonModule,
        CoreModule
    ],
    providers: [
        TipiPrestazioneStrutturaService,
        ClassiEconomicitaService
    ],
    entryComponents: [
        TipiPrestazioniStrutturaComponent,
    ]
})

export class StruttureModule {
    constructor(private injector: Injector) {
        const elements: any[] = [
            [TipiPrestazioniStrutturaComponent, 'app-tipi-prestazioni-struttura'],
        ];

        for (const [component, name] of elements) {
            const el = createCustomElement(component, { injector: this.injector });
            customElements.define(name, el);
        }
    }
}
