import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TipoContattoStruttura } from '../../../model/strutture/tipo-contatto-struttura';
import { TipiContattoStrutturaService } from '../../../services/amministrazione/tipi-contatto-struttura.service';
import { AlertService } from '../../../services/core/alert.service';
@Component({
  selector: 'app-dettaglio-tipi-contatto-struttura',
  templateUrl: './dettaglio-tipi-contatto-struttura.component.html'
})
export class DettaglioTipiContattoStrutturaComponent {
  public _tipoSelezionato: TipoContattoStruttura;

  @Input()
  set idDettaglioSelezionato(value: number) {
    if (value > 0) {
      this.tipiSvc.get(value).subscribe(
        data => {
          this._tipoSelezionato = data;
        },
        error => {
          this.alertService.error(error);
        });
    } else {
      this._tipoSelezionato = new TipoContattoStruttura();
    }
  }

  @Output()
  OnDettaglioClosed = new EventEmitter();

  constructor(protected tipiSvc: TipiContattoStrutturaService,
    protected alertService: AlertService
  ) { }

  salva(isValid: boolean) {
    if (!isValid) {
      this.alertService.error(
        'Attenzione, alcuni campi obbligatori non sono stati compilati correttamente, ricontrollare eventuali errori.');
      return;
    }
    this.alertService.reset();

    this.tipiSvc.salva(this._tipoSelezionato).subscribe(
      data => {
        if (data.result > 0) {
          this.alertService.success('Salvataggio completato con successo!');
          this.OnDettaglioClosed.emit();
        } else {
          this.alertService.error(data.error);
        }
      },
      error => {
        this.alertService.error(error);
      });
  }

  chiudi() {
    this._tipoSelezionato = null;
    this.OnDettaglioClosed.emit();
  }}
