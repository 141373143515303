import { Component } from '@angular/core';

@Component({
  selector: 'app-gestione-tipi-prestazione',
  templateUrl: './gestione-tipi-prestazione.component.html'
})
export class GestioneTipiPrestazioneComponent {
  public _idDettaglio = 0;

  dettaglioSelezionato(idDettaglio: number) {
    this._idDettaglio = idDettaglio;
  }

  chiudiDettaglio() {
    this._idDettaglio = 0;
  }
}
