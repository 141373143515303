import { Component, OnInit, Input } from '@angular/core';
import { Nazione } from '../../model/common/nazione';
import { Localizzazione } from 'src/app/model/common/localizzazione';
import { MatDialog } from '@angular/material';
import { LocalizzazioneDialogComponent } from './localizzazione-dialog.component';

@Component({
  selector: 'app-localizzazione',
  templateUrl: './localizzazione.component.html'
})

export class LocalizzazioneComponent implements OnInit {
  public _nazioni: Nazione[] = new Array<Nazione>();
  public _localizzazioni: Localizzazione[] = new Array<Localizzazione>();

  @Input()
  set nazioni(value: Nazione[]) {
    this._nazioni = value;
  }

  @Input()
  set localizzazioni(value: Localizzazione[]) {
    this._localizzazioni = value;
  }
  get localizzazioni() {
    return this._localizzazioni;
  }

  constructor( public dialog: MatDialog) {

   }

  ngOnInit() {

  }

  apriLocalizzazione() {
    const dialogRef = this.dialog.open(LocalizzazioneDialogComponent, {
      width: '800px',
      height: '600px',
      data: { nazioni: this._nazioni, _localizzazioni: this._localizzazioni }
    }).afterClosed().subscribe((result: undefined) => { });
  }
}
