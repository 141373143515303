import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { createCustomElement } from '@angular/elements';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import {NgModule, Injector} from '@angular/core';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatPaginatorIntl,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule,
} from '@angular/material';

import { MatListModule } from '@angular/material/list';
import { getItaPaginatorIntl } from './ita-paginator-intl';

import { ToolbarComponent } from './toolbar/toolbar.component';
import { CommonModule } from '@angular/common';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { ToolbarNotificationComponent } from './toolbar-notification/toolbar-notification.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { SidemenuItemComponent } from './sidemenu-item/sidemenu-item.component';
import { RouterModule } from '@angular/router';
import { AlertComponent } from './alert/alert.component';

import { AlertService } from './../services/core/alert.service';
import { AuthenticationService } from './../services/core/authentication.service';
import { LoginComponent } from './authentication/login/login.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { LocalizzazioneComponent } from './localizzazione/localizzazione.component';
import { LocalizzazioneDialogComponent } from './localizzazione/localizzazione-dialog.component';
import { LoaderService } from '../services/core/loader.service';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptorService } from '../services/core/interceptors/loader.interceptor';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    CdkTableModule,
    CdkTreeModule,
    PerfectScrollbarModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    MatFormFieldModule,
    MatListModule
  ],
  exports: [
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    CdkTableModule,
    CdkTreeModule,
    PerfectScrollbarModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    MatFormFieldModule,
    MatListModule,
    ToolbarComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    LoaderComponent,
    AlertComponent,
    LocalizzazioneComponent,
    LocalizzazioneDialogComponent
  ],
  providers: [
    {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MatPaginatorIntl, useValue: getItaPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    AlertService,
    AuthenticationService,
    LoaderService,
  ],
  declarations: [
    ToolbarComponent,
    FullscreenComponent,
    ToolbarNotificationComponent,
    UserMenuComponent,
    SidemenuComponent,
    SidemenuItemComponent,
    AlertComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    LocalizzazioneComponent,
    LocalizzazioneDialogComponent,
    LoaderComponent
  ],
  entryComponents: [
    AlertComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    LocalizzazioneDialogComponent
  ]
})

export class CoreModule {
  constructor(private injector: Injector) {
    const elements: any[] = [
      [AlertComponent, 'app-alert'],
      [LoginComponent, 'app-login'],
    ];

    for (const [component, name] of elements) {
      const el = createCustomElement(component, { injector: this.injector });
      customElements.define(name, el);
    }

  }
}
