import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ReturnValue } from '../../model/common/return-value';
import { ListaBase } from '../../model/core/lista-base';
import { TipoPrestazione } from '../../model/strutture/tipo-prestazione';

@Injectable({
  providedIn: 'root'
})
export class TipiPrestazioneService extends BaseService {

  get(id: number): Observable<TipoPrestazione> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazione/${id}`;
    return this.http.get<TipoPrestazione>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getAll(): Observable<Array<TipoPrestazione>> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazione/GetAll`;
    return this.http.get<Array<TipoPrestazione>>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  getRicerca(tipo: TipoPrestazione, descrizione: string, start: number, length: number): Observable<ListaBase<TipoPrestazione>> {
    if (!descrizione) {
      descrizione = '';
    }

    let idPadre: String = '';
    if (tipo && tipo.id) {
      idPadre = tipo.id.toString();
    }

    let urlParams = `${environment.apiEndpoint}/TipiPrestazione/GetRicerca?idPadre=${idPadre}`;
    urlParams += `&descrizione=${encodeURIComponent(descrizione)}&start=${start}&length=${length}`;

    return this.http.get<ListaBase<TipoPrestazione>>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  salva(objToSave: TipoPrestazione): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazione/`;
    const objToSend = JSON.stringify(objToSave);
    if (objToSave.id === undefined || objToSave.id === 0) {
        return this.http.post<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
    }
    return this.http.put<ReturnValue>(urlParams, objToSend, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }

  elimina(id: number): Observable<ReturnValue> {
    const urlParams = `${environment.apiEndpoint}/TipiPrestazione/${id}`;
    return this.http.delete<ReturnValue>(urlParams, this.jwt()).pipe(catchError(error => this.handleError(error)));
  }
}
