import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AlertService } from './../../services/core/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent implements OnInit {

  constructor(private snackBar: MatSnackBar, private alertService: AlertService) { }

  ngOnInit() {
    this.alertService.getMessage().subscribe(message => {
        if (message) {
          let classToUse: string;
          switch (message.type) {
            case 'success':
              classToUse = 'message-snackbar';
            break;
            case 'error':
              classToUse = 'error-snackbar';
            break;
            case 'warning':
              classToUse = 'warning-snackbar';
            break;
            default:
              classToUse = 'message-snackbar';
            break;
          }

          this.snackBar.open(message.text, '', {duration: 10000, verticalPosition: 'bottom', panelClass: [classToUse]});
        } else {
          this.snackBar.dismiss();
        }
      });
  }
}
