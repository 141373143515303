import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from './../../services/core/authentication.service';
import { User } from './../../model/core/user';
import { VociMenu } from './../../model/core/voci-menu';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})

export class SidemenuComponent implements OnInit {

  @Input() isUserLogged: boolean;
  @Input() iconOnly: boolean = false;

  public menus: VociMenu[];
  public subscription: Subscription;

  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.subscription = this.authenticationService.isUserLogged$.subscribe(
      item => {
        this.isUserLogged = item;
        if (this.isUserLogged === true) {
          const utente: User =  this.authenticationService.getCurrentUser();
          this.menus = utente.menu;
        }
      }
    );
  }
}
