import { Injectable } from '@angular/core';
import { BaseService } from '../core/base.service';
import { catchError } from 'rxjs/operators';
import { saveFile } from '../../core/helpers/file-download-helper';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticheService extends BaseService {

  struttureSenzaTipoPrestazione(): any {
    const urlParams = `${environment.apiEndpoint}/Statistiche/GetStruttureSenzaTipoPrestazione`;

    const fileName = 'StruttureSenzaTipoPrestazione.xlsx';
    this.http.get<Blob>(urlParams, this.jwtFilesExcel())
              .pipe(catchError(error => this.handleError(error)))
              .subscribe((res) => {saveFile(res, fileName);
      });
  }

  strutturePerTipoPrestazione(): any {
    const urlParams = `${environment.apiEndpoint}/Statistiche/GetStrutturePerTipoPrestazione`;

    const fileName = 'StrutturePerTipoPrestazione.xlsx';
    this.http.get<Blob>(urlParams, this.jwtFilesExcel())
              .pipe(catchError(error => this.handleError(error)))
              .subscribe((res) => {saveFile(res, fileName);
      });
  }
}
