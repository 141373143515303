import { Localizzazione } from '../common/localizzazione';

export class TipoPrestazione {
    id: number;
    idPadre: number;
    descrizione: string;
    richiediClasseEconomicita: boolean;
    visibileSuWeb: boolean;
    codiceScegliereSalute: number;

    padre: TipoPrestazione;
    descrizioniLoc: Localizzazione[];
}
